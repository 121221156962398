import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import IMAGES from "../../utils/images";
import BannerContainer from "../common/BannerContainer";

export default function CareerIntro() {
  return (
    <section>
      <div>
        <BannerContainer
          title="careers"
          heading="There are jobs... <br /> and then there are careers!"
          caption='<span className="underline"> Partner with us<br />across data,technology,<br />customer sucess and <br />support domains.</span>'
        />
      </div>
      <div
        id="analytics-bg"
        className="mt-12 items-center text-center relative"
      >
        <StaticImage
          className="inline-block w-full md:px-24"
          src="https://firebasestorage.googleapis.com/v0/b/admin-upload-6e7b6.appspot.com/o/Thinklokal-website%2Fimages%2F1student-using-laptop-computer-call-parents-abroad-young-woman-using-notebook-listen-watch-movie-min.jpg?alt=media&token=bfb3bd90-08b4-4b5d-b808-3638ee3e425c"
          alt="banner"
        />
        <div className="md:absolute flex flex-col text-left bg-[#1F1F1F] text-white p-16 md:w-1/4 md:h-3/4 right-48 top-24">
          <div className="flex-1">
            <div className="text-3xl pb-8">Ready to talk?</div>
            <div className="leading-loose">
              Send us your LinkedIn profile with one click.{" "}
            </div>
          </div>
          <div className="text-center mt-12">
            <a
              target="_blank"
              href="https://www.linkedin.com/company/thinklokal/"
              className="rounded-full bg-[#0077B5] px-12 py-4 text-white text-xl"
            >
              Linkedin
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
