import React from "react";
import ICONS from "../../utils/icons";

export default function CurrentOpenings() {
  const openingsList = [
    {
      name: "Data Scientist",
      narration:"Analysis of data analysis models to predict future trends and patterns",
    },
    {
      name: "Data Analyst",
      narration:"Use of customer-specific techology stacks for rapid-response analytics in a 'product-as-a-service' deliery model",
    },
    {
      name: "ETL Expert",
      narration: "Love to work with data and have a knack for solving complex ETL problems?",
    },
    {
      name: "Customer Success Manager",
      narration: "Understand and manage customer expectations and deliver on them, being unforgiving, persistent and intolerant on behalf of the customer",
    },
  ];

  return (
    <section className="md:py-16 px-6 md:px-24 my-4">
      
      <div>
        {openingsList.map((opening) => (
          <div className="p-6 md:p-6 flex flex-col md:flex-row justify-between items-center bg-[#F9F9F9] hover:bg-black hover:text-white cursor-pointer">
            <div>
              <div className="text-3xl md:text-4xl">
                {opening.name}
              </div>
              <div className=" font-semibold pt-2 md:pt-4">
                {opening.narration}
              </div>
            </div>
            
          </div>
        ))}
      </div>
    </section>
  );
}
