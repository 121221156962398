import React from "react";
import CareerIntro from "../components/careers/CareerIntro";
import CurrentOpenings from "../components/careers/CurrentOpenings";
import Footer from "../components/Footer";
import GetAQuote from "../components/home/GetAQuote";
import TopbarDark from "../components/TopbarDark";

export default function careers() {
  return (
    <main>
      <TopbarDark />
      <CareerIntro />
      <CurrentOpenings />
      <GetAQuote />
      <Footer />
    </main>
  );
}
